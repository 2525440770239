<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="prepMaterialGrid"
          ref="prepMaterialGridRef"
          :height="setHeight"
          :data-source="PrepMaterialDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <template #filterToolbar>
            <div class="d-flex">
              <dx-util-button icon="back" type="default" class="mx-half" @click="$router.go(-1)" />
              <dx-util-select-box
                v-model="selectedCostGroup"
                :data-source="costGroupDataSource"
                placeholder="Select cost group"
                display-expr="text" value-expr="value"
                :search-enabled="true"
                :grouped="true"
                :max-item-count="10"
                :show-clear-button="true"
                :width="300"
                @valueChanged="searchByFilter"
              />
            </div>
          </template>
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="false" :summary="false" />
          <dx-search-panel :visible="true" :width="300" placeholder="Search..." />

          <dx-column data-field="costGroup" width="20%" />
          <dx-column data-field="title" width="20%" />
          <dx-column data-field="inventoryType" caption="Inventory Type" :width="140" cell-template="inventoryTypeTemplate" />
          <dx-column data-field="threshold" caption="Threshold" :width="110" cell-template="thresholdTemplate" />
          <dx-column data-field="quantity" caption="Quantity" :width="110" cell-template="quantityTemplate" />
          <template #quantityTemplate="{data}">
            <div :class="resolveQuantityFormat(data)">
              {{ resolveQuantity(data) }}
            </div>
          </template>
           <template #thresholdTemplate="{data}">
            <div :class="resolveThresholdFormat(data)">
              {{ resolveThreshold(data) }}
            </div>
          </template>
          <template #inventoryTypeTemplate="{data}">
            <div :class="resolveInventoryTypeFormat(data)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="cost" :width="100" caption="Avg Cost" :format="'$ #,##0.##'" />
          <dx-column data-field="salePrice" :width="100" :format="'$ #,##0.##'" />
          <dx-column data-field="status" cell-template="statusTemplate" :width="100" />
          <template #statusTemplate="{data}">
            <div :class="resolveStatusFormat(data.value)" class="text-capitalize d-block">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="description" :allow-sorting="false" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="180" :allow-sorting="false" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div class="d-flex">
                <dx-util-button
                  type="warning" icon="bi bi-pencil-square"
                  class="mr-half" hint="Edit material item"
                  @click="editMaterialItem(data)"
                />
                <dx-util-button
                  type="danger" icon="bi bi-trash"
                  class="mr-half" hint="Delete material item"
                  @click="deleteMaterialItem(data)"
                />
                <dx-util-drop-down-button
                  id="materialActions"
                  ref="materialActionsRef"
                  :visible="data.data.inventoryType === 'INVENTORY'"
                  :split-button="false"
                  :use-select-mode="false"
                  :items="materialActions"
                  :drop-down-options="{ width: 200 }"
                  styling-mode="contained"
                  class="mr-half"
                  icon="menu"
                  display-expr="name"
                  key-expr="id"
                  item-template="item-temp"
                >
                  <template #item-temp="{data: item}">
                    <dx-util-button
                      :id="item.btnId"
                      :text="item.name"
                      :icon="item.icon"
                      :class="item.class"
                      :element-attr="item.elementAttr"
                      width="170"
                      @click="onMaterialActionClink($event, data, item.id)"
                    />
                  </template>
                </dx-util-drop-down-button>
              </div>
            </template>

          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    <div>
      <material-item-form
        :component-id="materialItemFormComponentId"
        :cost-groups="costGroups" :item-id="selectedItemId"
        @emit-form-saved="onItemFormSaved"
      />
    </div>
    <div>
      <material-purchase-form
        :component-id="materialPurchaseFormComponentId"
        :prep-material-item="selectedPrepMaterial"
        @emit-form-saved="onPurhcaseFormSaved"
      />
    </div>
    <div>
      <material-adjustment-form
        :component-id="materialAdjustmentComponentId"
        :prep-material-item="selectedPrepMaterial"
        @emit-form-saved="onPurhcaseFormSaved"
      />
    </div>
    <div>
      <material-reduction-form
        :component-id="materialReductionComponentId"
        :prep-material-item="selectedPrepMaterial"
        @emit-form-saved="onPurhcaseFormSaved"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { confirm } from 'devextreme/ui/dialog'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import costGroupService from '@/http/requests/finance/costGroupService'
import Pager from '@core/dev-extreme/utils/pager'
import prepMaterialService from '@/http/requests/prep-material/prepMaterialService'
import { InventoryTypeEnum } from '@/enums'
import { PrepMaterialDataSource } from './prepMaterialStore'

export default {
  components: {
    'material-item-form': () => import('../components/PrepMaterialItemForm.vue'),
    'material-purchase-form': () => import('../components/PrepMaterialPurchaseForm.vue'),
    'material-reduction-form': () => import('../components/PrepMaterialReductionForm.vue'),
    'material-adjustment-form': () => import('../components/PrepMaterialAdjustmentForm.vue'),
  },
  mixins: [GridBase],
  props: {
    qCostGroupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      PrepMaterialDataSource,
      searchQuery: '',
      costGroups: [],
      costGroupDataSource: [],
      selectedCostGroup: this.qCostGroupId,
      showCostGroupSelection: false,
      materialItemFormComponentId: '',
      selectedItemId: null,
      materialPurchaseFormComponentId: '',
      selectedPrepMaterial: {},
      materialAdjustmentComponentId: '',
      materialReductionComponentId: '',
    }
  },
  computed: {
    materialActions() {
      return [
        {
          id: 'view-purchase',
          btnId: 'btn-view',
          visible: true,
          name: 'View Purchases',
          icon: 'bi bi-list-ul',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'make-purchase',
          btnId: 'btn-purchase',
          visible: true,
          name: 'Make Purchase',
          icon: 'icon bi-coin',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'adjust',
          btnId: 'btn-adjust',
          visible: true,
          name: 'Adjust inventory',
          icon: 'bi bi-arrow-bar-right',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'reduce',
          btnId: 'btn-reduce',
          visible: true,
          name: 'Reduce inventory',
          icon: 'bi bi-arrow-bar-down',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
      ]
    },
  },
  mounted() {
    this.getCostGroups()
    this.initialLoad()
    this.showCostGroupSelection = true
  },
  methods: {
    initialLoad() {
      const filters = {
        companyId: 0,
        q: this.searchQuery,
        costGroupId: this.selectedCostGroup,
      }
      PrepMaterialDataSource.searchValue(filters)
      PrepMaterialDataSource.load()
    },
    async getCostGroups() {
      const pager = new Pager()
      const result = await costGroupService.fetchAll(pager.staticPageable)
      const itemsData = result.data.content.map(el => ({
        text: el.name,
        value: el.id,
        inventoryType: el.inventoryType,
      }))
      this.costGroups = itemsData
      const inventoryItems = []
      const nonInventoryItems = []
      itemsData.forEach(item => {
        if (item.inventoryType === 'INVENTORY') {
          inventoryItems.push(item)
        } else if (item.inventoryType === 'NONE_INVENTORY') {
          nonInventoryItems.push(item)
        }
      })
      const formattedInventoryItems = inventoryItems.map(item => ({
        key: 'INVENTORY',
        items: [
          {
            text: item.text,
            value: item.value,
          },
        ],
      }))

      const formattedNonInventoryItems = nonInventoryItems.map(item => ({
        key: 'NON-INVENTORY',
        items: [
          {
            text: item.text,
            value: item.value,
          },
        ],
      }))
      this.costGroupDataSource = [...formattedInventoryItems, ...formattedNonInventoryItems]
    },
    searchByFilter() {
      const filters = {
        companyId: 0,
        q: this.searchQuery,
        costGroupId: this.selectedCostGroup,
      }
      // this.updateQueryString(filters)
      PrepMaterialDataSource.searchValue(filters)
      PrepMaterialDataSource.reload()
    },
    updateQueryString(filters) {
      this.$router.push({ query: { qCostGroupId: filters.costGroupId } })
    },
    resolveStatusFormat(status) {
      if (status.toLowerCase() === 'active') return 'badge badge-light-success'
      if (status.toLowerCase() === 'passive') return 'badge badge-light-secondary'
      return 'badge badge-primary-light'
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filters.query = ''
        this.filters.query = e.value
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.searchQuery = query.trim()
          e.component.searchByText('')
          this.searchByFilter()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.searchQuery = query
          this.searchByFilter()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.searchQuery = ''
          this.searchByFilter()
        }
      }
    },
    editMaterialItem(data) {
      this.selectedItemId = data.data.id
      this.materialItemFormComponentId = uuidv4()
    },
    deleteMaterialItem(data) {
      const metarialItemId = data.data.id
      const confirmResult = confirm(
        "Are you sure you want to delete this material item? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await prepMaterialService.deleteById(metarialItemId)
          if (result) {
            PrepMaterialDataSource.reload()
          }
        }
      })
    },
    onItemFormSaved() {
      this.searchByFilter()
    },
    adjustMaterialInventory(data) {
      this.materialAdjustmentComponentId = uuidv4()
      const prepMaterialItem = data
      this.selectedPrepMaterial = prepMaterialItem
    },
    reduceMaterialInventory(data) {
      this.materialReductionComponentId = uuidv4()
      const prepMaterialItem = data
      this.selectedPrepMaterial = prepMaterialItem
    },
    purchaseMaterialItem(data) {
      this.materialPurchaseFormComponentId = uuidv4()
      const prepMaterialItem = data
      this.selectedPrepMaterial = prepMaterialItem
    },
    onPurhcaseFormSaved() {
      this.selectedPrepMaterial = {}
      this.selectedPrepMaterialTitle = ''
      this.searchByFilter()
    },
    viewMaterialPurchases(data) {
      const itemId = data.id
      this.$router.push({ name: 'route-material-purchases', query: { qPrepMaterialId: itemId } })
    },
    resolveQuantity(e) {
      const item = e.data
      const text = e.value
      if (item.inventoryType === InventoryTypeEnum.NONE_INVENTORY.value) return 'N/A'
      if (item.inventoryType === InventoryTypeEnum.INVENTORY.value && text === 0) return 'Out of stock'
      return text?.toLocaleString('en-US')
    },
    resolveQuantityFormat(e) {
      const item = e.data
      const value = e.value
      if (item.inventoryType === InventoryTypeEnum.NONE_INVENTORY.value) return 'badge badge-light-secondary d-block'
      if (item.quantity === 0) return 'badge badge-light-danger d-block'
      if (item.threshold >= value) return 'badge badge-light-warning d-block'
      return ''
    },
    resolveThreshold(e) {
      const item = e.data
      const text = e.value
      if (item.inventoryType === InventoryTypeEnum.NONE_INVENTORY.value) return 'N/A'
      return text?.toLocaleString('en-US')
    },
    resolveThresholdFormat(e) {
      const item = e.data
      if (item.inventoryType === InventoryTypeEnum.NONE_INVENTORY.value) return 'badge badge-light-secondary d-block'
      return ''
    },
    resolveInventoryTypeFormat(e) {
      const item = e.data
      if (item.inventoryType === InventoryTypeEnum.NONE_INVENTORY.value) return 'badge badge-light-success d-block'
      if (item.inventoryType === InventoryTypeEnum.INVENTORY.value) return 'badge badge-light-warning d-block'
      return ''
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Material Purchases',
          icon: 'bi bi-receipt-cutoff',
          type: 'default',
          onClick: () => {
            this.$router.push({ name: 'route-material-purchases' })
          },
        },
        location: 'after',
      })
      // toolbarItems.push({
      //   widget: 'dxButton',
      //   options: {
      //     text: 'Material Inventory',
      //     icon: 'bi bi-inboxes',
      //     type: 'success',
      //     onClick: () => {
      //       this.$router.push({ name: 'route-material-inventory' })
      //     },
      //   },
      //   location: 'after',
      // })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.selectedItemId = null
            this.materialItemFormComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            PrepMaterialDataSource.reload()
          },
        },
        location: 'after',
      })
    },
    onMaterialActionClink(e, row, id) {
      const { data } = row
      if (!id) return
      switch (id) {
        case 'view-purchase':
          this.viewMaterialPurchases(data)
          break
        case 'make-purchase':
          this.purchaseMaterialItem(data)
          break
        case 'adjust':
          this.adjustMaterialInventory(data)
          break
        case 'reduce':
          this.reduceMaterialInventory(data)
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss">
#btn-view {
  .dx-icon {
    font-size: 1.3rem;
    color: #0389cc;
    margin-right: 0.5rem;
  }
}
#btn-adjust #btn-reduce {
  .dx-icon {
    font-size: 1.3rem;
    color: #0e2330;
    margin-right: 0.5rem;
  }
}
#btn-purchase {
  .dx-icon {
    font-size: 1.3rem;
    color: #2ad427;
    margin-right: 0.5rem;
  }
}
#materialActions  {
  .dx-icon {
    font-size: 1.143rem;
    color: #FFFFFF;
    margin-right: 0.5rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #13c5d8;
  }
  .dx-buttongroup-item {
    padding-left: 1px;
    padding-right: 1px;
    border-radius: 6px;
  }
}
</style>
