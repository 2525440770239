import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all cost groups
  async fetchAll(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.costGroup.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all cost groups
  async fetchAllList() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.costGroup.Controller.findAllList())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get cost group by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.costGroup.Controller.findById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create cost group
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.costGroup.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update cost group
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.costGroup.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete cost group
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.costGroup.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
