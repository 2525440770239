import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  // Convert PrepItemCosts to ItemCharge
  async convertToItemCharge() {
    const promise = new Promise((resolve, reject) => {
      axios
        .post('/api/prep/prep-item-cost/convert-cost')
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchAllMatchingName(name, pageable) {
    const result = await this.getByQuery({ q: name }, pageable)
    return result.data.content
  },
  async getByQuery(filters, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.prepMaterial.Controller.getByQuery(pageable), filters)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchAllByCostGroup(type, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.prepMaterial.Controller.findAllByCostGroup(type, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get prep material by id
  async fetchInfoById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.prepMaterial.Controller.findInfoById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create prep material
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.prepMaterial.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update prep material
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.prepMaterial.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete prep material
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.prepMaterial.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
